import { Subject } from 'rxjs'
import { ArticleAddOptions } from '@obeta/models/lib/models/Article/ArticleListItem'
import {
  AddSelectedCartTemplateItemsToCartOptions,
  AddCartTemplateToCartOptions,
  AmountRoundUpOptions,
  CartApprovalOptions,
  CartMoveOptions,
  DeleteArticleSingleOptions,
  DeleteArticleMultipleOptions,
  EmptyCartOptions,
  DeleteAddressOptions,
  OrderFailureOptions,
  OrderLimboOptions,
  OrderPendingOptions,
  OrderSuccessOptions,
  OrderUpdateOptions,
  TemplateAddOptions,
  GenericOptions,
  NotificationWithoutDataOptions,
  DeleteTemplateOptions,
  CartTemplateVisibilityOptions,
  AddToCartMultipleOptions,
  DeleteCartTemplateItemSingleOptions,
  DeleteCartTemplateItemsOptions,
  ElbridgeItemsNotFoundOptions,
  AddCartTemplatesToCartTemplateOptions,
  OfferUpdateOptions,
  AddOrReplaceOfferInCartOptions,
  AddCartTemplateItemsToCartTemplateOptions,
  CartTemplateCreatedOptions,
  AddCartTemplateItemsToCartOptions,
  SapOfferPdfCreatedOptions,
  AddToCartFromIdsXmlItemsNotFoundOptions,
  AddOrderItemsToCartOptions,
  VerifyOfferInShoppingCartResultOptions,
  SapInvoiceCreatedOptions,
  UpdateUserOptions,
  FastProductEntryOptions,
  FastProductEntryFileUploadSuccessOptions,
  CreditLimitPreAlertOptions,
  ConstructionDocumentationOptions,
  ConstructionDocumentationCreatedOptions,
  CartLockedOptions,
  ChainingOptions,
} from '@obeta/models/lib/models/Notification/Notification'

export enum EventType {
  Toast,
  Alert,
  Data,
  Snackbar,
}

export enum NotificationType {
  AddCartTemplateToCart,
  AddCartTemplateItemsUnpurchaseableProductsToCartWarning,
  AddCartTemplateItemsUnpurchaseableProductsToCartTemplateWarning,
  AddCartTemplateItemsToCart,
  AddCartTemplateItemsToCartTemplate,
  AddOrderItemsToCartTemplate,
  AddCartTemplatesToCartTemplate,
  AddOfferToCartTemplate,
  AddOrReplaceOfferInCart,
  AddSelectedCartTemplateItemsToCart,
  AmountRoundUp,
  CartApproval,
  CartLocked,
  CartMove,
  CartTemplateCreated,
  CartTemplateVisibilitySetToPrivate,
  CartTemplateVisibilitySetToPrivateAccessDenied,
  CartTemplateVisibilitySetToPublic,
  ConstructionDocumentationCreated,
  ConstructionDocumentationFailed,
  ConstructionDocumentationPending,
  DeleteAddress,
  DeleteArticleSingle,
  DeleteArticleMultiple,
  DeleteCartTemplateSingle,
  DeleteCartTemplateMultiple,
  DeleteCartTemplateItemSingle,
  DeleteCartTemplateItems,
  DeliverySlipPDFCreated,
  DeliverySlipPDFFailed,
  DeliverySlipPDFPending,
  DuplicateCartTemplateSingle,
  EmptyCart,
  FastProductEntryFileUploadErrorCommon,
  FastProductEntryFileUploadErrorNotFound,
  FastProductEntryFileUploadProcessing,
  FastProductEntryFileUploadSuccess,
  FastProductEntryProductDelete,
  GenericAddDiverseProductsOrCancelledItemsToCartTemplateWarning,
  GenericAddNonPurchasableProductsToCartTemplateWarning,
  InterfaceDatanormError,
  InterfaceDatanormPending,
  InterfaceDatanormSuccess,
  OfferAdd,
  OfferUpdate,
  OfferDelete,
  OfferNoPermission,
  OfferAddDiverseProductsOrCancelledItemsToCartWarning,
  OfferAddDiverseProductsToCartTemplateWarning,
  OfferAddUnpurchasableProductsToCartWarning,
  OrderAddDiverseArticlesToCartWarning,
  OrderAddUnpurchasableArticlesToCartWarning,
  OrderAddDiverseArticlesToCartTemplateWarning,
  OrderConfirmationPDFCreated,
  OrderConfirmationPDFFailed,
  OrderConfirmationPDFPending,
  OfferExportCreated,
  OfferExportFailed,
  OfferExportPending,
  OrderFailure,
  OrdersExportFailed,
  OrdersExportPending,
  OrderLimbo,
  OrderNotFound,
  OrderPending,
  OrderSuccess,
  OrdersExportCreated,
  OrderUpdate,
  TemplateAdd,
  CustomProductToTemplateAdd,
  Success,
  Toast,
  Alert,
  Error,
  Warning,
  Confirm,
  Pending,
  AddToCart,
  AddToCartMultiple,
  OrderIdMissingInResponse,
  ElbridgeNotFoundItems,
  SapOfferPdfCreated,
  SapOfferPdfFailed,
  AddToCartFromIdsXmlItemsNotFound,
  AddOrderItemsToCart,
  OfferNotValid,
  CartHasItemsNoPickupPossible,
  SapInvoiceCreated,
  SapInvoiceFailed,
  SapInvoicePending,
  UpdateUser,
  CreditLimitPreAlert,
}

export enum FeedType {
  Cart = 'Cart',
  CartTemplate = 'CartTemplate',
  Offer = 'Offer',
  Order = 'Order',
  OrderMetaData = 'OrderMetaData',
  SmallConstructionDocumentation = 'SmallConstructionDocumentation',
  LargeConstructionDocumentation = 'LargeConstructionDocumentation',
  UserAddress = 'UserAddress',
}

export interface AnnouncementEvent {
  type?: EventType // remove
  title?: string
  message: string
  /* add an optional id to mark events as duplicates */
  id?: string
  opts?: ArticleAddOptions
  duration?: number
}

// FIXME: it's badly typed. User won't understand what options he should use
export interface NotificationEvent {
  type: EventType
  uid?: string
  frontendClientId?: string
  notificationType: NotificationType
  id: string
  milliSecondsForTimeout?: number
  options:
    | AddCartTemplateToCartOptions
    | AddCartTemplateItemsToCartOptions
    | AddCartTemplateItemsToCartTemplateOptions
    | AddCartTemplatesToCartTemplateOptions
    | AddOrReplaceOfferInCartOptions
    | AddOrderItemsToCartOptions
    | AddSelectedCartTemplateItemsToCartOptions
    | AddToCartMultipleOptions
    | AmountRoundUpOptions
    | CartApprovalOptions
    | CartMoveOptions
    | CartTemplateVisibilityOptions
    | CartTemplateCreatedOptions
    | ConstructionDocumentationOptions
    | ConstructionDocumentationCreatedOptions
    | DeleteAddressOptions
    | DeleteArticleSingleOptions
    | DeleteArticleMultipleOptions
    | DeleteCartTemplateItemSingleOptions
    | DeleteCartTemplateItemsOptions
    | DeleteTemplateOptions
    | EmptyCartOptions
    | FastProductEntryOptions
    | FastProductEntryFileUploadSuccessOptions
    | OrderFailureOptions
    | OrderLimboOptions
    | OrderPendingOptions
    | OrderSuccessOptions
    | OrderUpdateOptions
    | OfferUpdateOptions
    | TemplateAddOptions
    | GenericOptions
    | NotificationWithoutDataOptions
    | AnnouncementEvent
    | ElbridgeItemsNotFoundOptions
    | AddToCartFromIdsXmlItemsNotFoundOptions
    | SapOfferPdfCreatedOptions
    | VerifyOfferInShoppingCartResultOptions
    | SapInvoiceCreatedOptions
    | UpdateUserOptions
    | CreditLimitPreAlertOptions
    | CartLockedOptions
    | ChainingOptions
}
const eventSubscription: Subject<NotificationEvent> = new Subject()
export const getEventSubscription = () => eventSubscription
