import { gql } from '@apollo/client'

export const ARTICLE_DETAILS = gql`
  query getProductDetails($sapIds: [String!], $obetaIds: [String!], $dehaIds: [String!]) {
    getProducts(sapIds: $sapIds, obetaIds: $obetaIds, dehaIds: $dehaIds) {
      id
      sapId
      labels {
        ... on Label {
          text
          type
        }
        ... on CustomLabel {
          text
          color
        }
      }
      isSendable
      isCutProduct
      title
      bulkPrices {
        amount
        unit
      }
      dop {
        dop
      }
      supplierArticleId
      supplierArticleId2
      eans
      oxomiId
      supplierId
      dehaId
      obetaId
      unit
      articleDescription
      minimumAmount
      isTopseller
      type
      elbridgeConfigurators {
        configuratorName
        configuratorUrl
        productGroupKey
      }
      supplierImageData {
        large
      }
      imageData {
        images {
          large
        }
      }
      images {
        width
        url
      }
      packagingUnit
      priceDimension
      variants {
        sapId
        title
        product {
          sapId
          obetaId
          dehaId
        }
      }
      categories {
        deha {
          id
          title
          productCount
        }
        obeta {
          id
          title
          productCount
        }
      }
      catalogInfo {
        year1
        year2
        page
      }
      catalogDescription
      etimData {
        value1
        value2
        featureText
        featureId
        classId
        type
        unit
      }
    }
  }
`
