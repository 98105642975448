import { useFlags } from 'launchdarkly-react-client-sdk'

// Feature toggles with fallback variations
export const featureToggles = {
  SHOP_VERSION: '',
  UseOfferItemsCSVExport: false,
  UseLargeBauDoc: false,
  UseOrderCSVExport: false,
  UsePackageTrackingInfos: false,
  UseOrdersFeedback: false,
  NOTIFICATION_UNDO_BUTTON: false,
  UsePaymentProvider: false,
  ShowDialogForDiscontinuedItemWithLimitedStock: false,
  UseSearchFeedback: false,
  UseNewInterfacesPage: false,
  UseNewSupplierOverview: false,
  UseOrdersSearchWithOptions: false,
  UsePrintBauDoc: false,
  UseIndividualDataSheetToggle: false,
  UseNewWarehouses: false,
  UseFastProductEntryFeedback: false,
  UseCreditLimitPreAlert: false,
  useAddToCartProcessIndicator: false,
  UseAlgoliaDrivenPromotionBanner: false,
  UseNewCompanySettings: false,
  UseRecommendationsPanelOnStartPage: false,
  UseUserManagementUpdatedForm: false,
  useRenderSupplierLogoOnArticleDetailPageViaImgProxy: false,
  UseShowLabelAlreadyinCart: false,
  UseShowLabelXTimesPurchased: false,
  UseShowLabelTopseller: false,
  UseShowCustomLables: false,
  UseNewProjectPages: false,
} as const

export type Feature = keyof typeof featureToggles

export const useFeatureToggle = (feature: Feature) => {
  const flags = useFlags()

  return flags[feature] ?? false
}
