import { FC, useEffect, useMemo, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { LDProvider, ProviderConfig, useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { LDContext } from 'launchdarkly-js-client-sdk'
import featureToggleService from '../../hooks/feature-toggles/FeatureToggleService'
import { useInitialUserDataV2 } from '../../hooks/useUserDataV2'
import { getCookiebotStatisticsFlag } from './utils/getCookiebotFlags'
import { buildLaunchDarklyContext } from './utils/buildLaunchDarklyContext'
import { useUserContextUpdate } from './useUserContextUpdate'
import { useCookiebotStatisticsConsentHandling } from './useCookiebotStatisticsConsentHandling'

const LAUNCH_DARKLY_BASE_CONFIG: ProviderConfig = {
  clientSideID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
  deferInitialization: true,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  options: {
    inspectors: [
      {
        type: 'flag-used',
        name: 'dd-inspector',
        method: (key, detail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value)
        },
      },
    ],
  },
}

const initialiseDatadogRum = () => {
  if (
    process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID &&
    process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN &&
    getCookiebotStatisticsFlag()
  ) {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      env: process.env.NEXT_PUBLIC_DATADOG_ENVIRONMENT,
      version: process.env.NEXT_PUBLIC_DATADOG_VERSION,
      site: 'datadoghq.eu',
      service: 'obeta-shop-frontends:shop',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      enableExperimentalFeatures: ['clickmap', 'feature_flags'],
      allowedTracingUrls: [
        {
          match: process.env.NEXT_PUBLIC_TRACING_REGEX
            ? new RegExp(process.env.NEXT_PUBLIC_TRACING_REGEX)
            : '',
          propagatorTypes: ['tracecontext'],
        },
      ],
      beforeSend: (event, context) => {
        // Collect operationName and variables
        if ('requestInit' in context && context?.requestInit) {
          if ('body' in context.requestInit && context.requestInit.body) {
            // @ts-expect-error: Object is possibly 'null'.
            const body = JSON.parse(context.requestInit.body)
            if (event?.context) {
              event.context.operationName = body.operationName ?? ''
              event.context.variables = body.variables ?? {}
            }
          }
        }
        return true
      },
    })
    datadogRum.startSessionReplayRecording()
  }
}

const LaunchDarklyFlagsToDatadogSync: FC = () => {
  const flags = useFlags()

  useMemo(() => {
    const objectFromProxy = JSON.parse(JSON.stringify(flags))

    datadogRum.setGlobalContextProperty('featureFlags', objectFromProxy)
  }, [flags])

  return null
}

/**
 * The component integrates and initializes third party tools, which are summarized here as Operations,
 * This term encompasses the activities and tools involved in managing and monitoring the operational aspects of a system
 */
export const OperationsIntegration: FC = ({ children }) => {
  const [launchDarklyContext, setLaunchDarklyContext] = useState<LDContext | undefined>()
  const userV2Data = useInitialUserDataV2()

  const updateLaunchDarklyContext = () => {
    const nextLaunchDarklyContext = buildLaunchDarklyContext(userV2Data)
    if (nextLaunchDarklyContext?.key !== launchDarklyContext?.key) {
      setLaunchDarklyContext(nextLaunchDarklyContext)
    }
  }

  useEffect(() => {
    initialiseDatadogRum()
  }, [])

  useCookiebotStatisticsConsentHandling(() => {
    initialiseDatadogRum()
    updateLaunchDarklyContext()
  })

  useUserContextUpdate(updateLaunchDarklyContext)

  return (
    <LDProvider {...LAUNCH_DARKLY_BASE_CONFIG} context={launchDarklyContext}>
      <LaunchDarklyFlagsToDatadogSync />
      <FeatureToggleContextSync launchDarklyContext={launchDarklyContext} />
      {children}
    </LDProvider>
  )
}

const FeatureToggleContextSync: FC<{ launchDarklyContext?: LDContext }> = ({
  launchDarklyContext,
}) => {
  const ldClient = useLDClient()
  const ldContext = ldClient?.getContext()
  useEffect(() => {
    if (launchDarklyContext) ldClient?.identify(launchDarklyContext)
    featureToggleService.setClient(ldClient)
  }, [launchDarklyContext, ldClient, ldContext])

  return null
}
