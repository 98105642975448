import { useEffect } from 'react'
import { Device } from '@capacitor/device'
import { datadogRum } from '@datadog/browser-rum'

export const useAnalyticsSystemLanguage = () => {
  useEffect(() => {
    const trackSystemLanguage = async () => {
      const systemLanguage = await Device.getLanguageCode()

      datadogRum.setGlobalContextProperty('userLanguage', systemLanguage.value)
    }

    trackSystemLanguage()
  }, [])
}
